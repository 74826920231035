
import axios from 'axios'
import ElementUI from 'element-ui'
import Vue from 'vue'

import router from '../router/index.js'
import store from '../store/index.js'

process.env.NODE_ENV == 'production' ? axios.defaults.baseURL = process.env.VUE_APP_BASE_URL : axios.defaults.baseURL= '/api';

Vue.prototype.$axios = axios;

let index = 0;
let div;

let ajax = data => {
	if(!data.hideMask) {
		index ++;
	
		if(!document.getElementById('loading')) {
			div = document.createElement('div');
	
			document.body.appendChild(div);
	
			div.innerHTML = `
				<style scoped>
					#loading{
						opacity: 0;
						transition-property: all;
						transition-duration: 0.3s;
					}
					#loading.on{
						opacity: 1;
					}
				</style>
				
				<div style="height: 100%;" flex="main:center cross:center dir:top">
					<img style="width: 100px; " src="${process.env.BASE_URL}static/img/loading2.gif" />
					<span style="color: #fff; font-size: 14px;">正在加载中</span>
				</div>
			`;
			div.id = 'loading';
			div.style.background = 'rgba(0, 0, 0, 0.6)';
			div.style.position = 'fixed';
			div.style.left = '0';
			div.style.top = '0';
			div.style.right = '0';
			div.style.bottom = '0';
			div.style.zIndex = '999999999';
		
			setTimeout(() => {
				div.className = 'on';
			}, 1);
		}
	}

	!data.method || data.method == 'post' ? data.method = 'post' : data.method = 'get';

	// if(data.data && typeof data.data.parentId == 'undefined') {
	// 	if(store.state.userInfo.roleId == 5) {
	// 		data.data.parentId = store.state.userInfo && store.state.userInfo.parentId;
	// 	}
	// }

	if(data.data && !data.data.userId && data.data.userId == 'undefined') {
		data.data.userId = store.state.userInfo && store.state.userInfo.userId;
	}

	axios({
		method: data.method,
		url: data.thirdPartyUrl ? data.url : data.url,
		headers: {Authorization: localStorage.getItem('userToken') || 'null', sysCode: 'dvp-oms'},
		// headers: {Authorization: '8da9aabd-9327-4fab-aa9a-9cd3812f6dbd'},
		params: data.method == 'get' ? data.data : {}, // get用
		data: data.method == 'post' ? (data.data || {}) : {} // post用
	})
	.then(res => {
		if(!data.hideMask) {
			index --;

			if(index <= 0) {
				index = 0;

				setTimeout(() => {
					div.className = '';

					setTimeout(() => {
						div.remove();
					}, 300);
				}, 1);
			}
		}

		// 导出文件
		if(data.export) {
			window.open(res.request.responseURL);
			return false;
		}

		// 第三方接口
		if(data.thirdPartyUrl) {
			data.success(res.data);
			return;
		}

		if(data.url == '/logout' || data.url == 'logout') {
			data.success(res.data);
			return false;
		}

		if(res.data.code == 4001) {
			if(data.url != '/cg/user/info' && data.url != 'cg/user/info') {
				sessionStorage.removeItem('keepAlive');
				sessionStorage.removeItem('menuActiveUrl');
				sessionStorage.removeItem('labelList');
				sessionStorage.removeItem('checkLabelName');
	
				localStorage.removeItem('userToken');
				router.replace({name: 'login'});
			}else {
				if(data.isUserCenter) {
					sessionStorage.removeItem('keepAlive');
					sessionStorage.removeItem('menuActiveUrl');
					sessionStorage.removeItem('labelList');
					sessionStorage.removeItem('checkLabelName');
		
					localStorage.removeItem('userToken');
					router.replace({name: 'login'});
				}else {
					store.state.token = ''
					store.state.userInfo = {}
					localStorage.removeItem('userToken')
				}
			}
		}

		if(data.success && res.data && res.data.code == 4001){
			if(data.url != '/cg/user/info' && data.url != 'cg/user/info') {
				ElementUI.Message.closeAll();
	
				ElementUI.Message({
					showClose: true,
					message: res.data.message,
					type: 'error'
				});
			}else {
				if(data.isUserCenter) {
					ElementUI.Message.closeAll();
		
					ElementUI.Message({
						showClose: true,
						message: res.data.message,
						type: 'error'
					});
				}
			}

		}else if(data.success && res.data && res.data.code != 200) {
			ElementUI.Message({
				showClose: true,
				message: res.data.message,
				type: 'error'
			});
		}

		store.state.systemTimer = res.data && res.data.sysTime;
		data.success && res.data && res.data.code == 200 ? data.success(res.data) : '';
	})
	.catch(error => {
		if(!data.hideMask) {
			index --;

			if(index <= 0) {
				index = 0;

				setTimeout(() => {
					div.className = '';

					setTimeout(() => {
						div.remove();
					}, 300);
				}, 1);
			}
		}

		
		if(!error.request) {
			console.log(error);
			return false;
		}
		let res = error.request.response;
		res ? res = JSON.parse(res) : '';

		ElementUI.Message.closeAll();
		ElementUI.Message({
			showClose: true,
			message: res.message,
			type: 'error'
		});

		if((res.code == 'P030002' || res.code == 'P030003') && data.url != '/cg/user/info' && data.url != 'cg/user/info') {
			if(router.history.current.name != 'home') {
				router.replace({name: 'home'});
				return false
			};
		};

		data.erros ? data.erros(error) : '';
	})
}

export default ajax;