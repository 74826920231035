<template>
	<div :class="{ hidden: hidden }" class="pagination-container">
		<el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize"
			:layout="layout" :page-sizes="pageSizes" :total="total" v-bind="$attrs" :pager-count="pagerCount"
			@size-change="handleSizeChange" @current-change="handleCurrentChange" :popper-class="popperClass">
			<span>当前展示</span>
		</el-pagination>
	</div>
</template>

<script>
/* import { scrollTo } from "@/utils/scroll-to"; */

export default {
	name: "pagination",
	props: {
		popperClass: {
			type: String,
			default: "selwrap-pagination",
		},
		pagerCount: {
			type: Number,
			default: 5,
		},
		total: {
			required: true,
			type: Number,
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 20,
		},
		pageSizes: {
			type: Array,
			default() {
				return [10, 20, 30, 50];
			},
		},
		layout: {
			type: String,
			default: "total, sizes, prev, pager, next, jumper",
		},
		background: {
			type: Boolean,
			default: true,
		},
		autoScroll: {
			type: Boolean,
			default: true,
		},
		hidden: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		currentPage: {
			get() {
				return this.page;
			},
			set(val) {
				this.$emit("update:page", val);
			},
		},
		pageSize: {
			get() {
				return this.limit;
			},
			set(val) {
				this.$emit("update:limit", val);
			},
		},
	},
	methods: {
		handleSizeChange(val) {
			this.$emit("pagination", { page: this.currentPage, limit: val });
			/* if (this.autoScroll) {
			  scrollTo(0, 800);
			} */
		},
		handleCurrentChange(val) {
			this.$emit("pagination", { page: val, limit: this.pageSize });
			/* if (this.autoScroll) {
			  scrollTo(0, 800);
			} */
		},
	},
};
</script>

<style scoped lang="scss">
::v-deep {
	.el-pagination {
		height: 22px !important;
		width: 100%;

		.el-pagination__total {
			color: #fff;
		}

		.el-pagination__sizes {
			.el-input {
				input {
					background: #233672;
					border-radius: 4px;
					color: #fff;
					border: none;
				}
			}
		}

		.el-pagination__jump {
			display: flex;
			align-items: center;
			color: #fff;
			line-height: 100% !important;

			.el-input{
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			input {
				width: 27px;
				height: 19px;
				background: #233672;
				border-radius: 4px;
				border: none;
				color: #fff;
			}
		}

		.btn-prev {
			.el-icon-arrow-left:before {
				content: "\e6ea";
				color: #0e6dd6;
			}
		}

		.el-pager {
			.number {
				margin: 0 5px;
				background: #040a11;
				border-radius: 4px;
				font-size: 12px;
				font-weight: 500;
				color: #54677e !important;

				&.active {
					color: #fff !important;
					background-color: #233672;
				}
			}

			.more {
				width: 19px;
				height: 19px;
				background-color: rgba(0, 0, 0, 0);
				color: #54677e !important;
				padding: 0;
				margin: 0;
				min-width: 0;
				font-size: 12px;
			}
		}

		.btn-next {
			.el-icon-arrow-right:before {
				content: "\e6e9";
				color: #0e6dd6;
			}
		}

		button[type="button"] {
			background-color: rgba(0, 0, 0, 0);
			color: #2a4662;
		}

		.el-select-dropdown {
			background: red;
		}
	}
}
</style>

<style scoped lang="scss">
@import "~@/styles/pagination.scss";
</style>
