<template>
	<div flex-box="0" style="margin-top: auto;">
		<div v-if="$store.state.systemType == '福鼎市'" class="keep-on-record" flex="cross:center main:center">
			<img src="../assets/imgs/index/keep-on-record-img.png" /><p>电信备案号：闽ICP备2021012120号-1 闽公网安备35098202000190号</p><img src="../assets/imgs/index/keep-on-record-icon.png" />
			<div style="width: 50px;"></div>
			技术支撑单位：福建长弓睿集信息科技有限公司
		</div>
		<div v-if="$store.state.systemType == '冷水滩区'" class="keep-on-record" flex="cross:center main:center">
			<img src="../assets/imgs/index/keep-on-record-img.png" /><p>备案号：湘ICP备2024042261号</p>
			<div style="width: 50px;"></div>
			技术支撑单位：福建长弓睿集信息科技有限公司
		</div>
	</div>
</template>

<script>
export default {
	name: 'keepOnRecord',
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
.keep-on-record{
	position: relative;
	bottom: 0;
	z-index: 99;
	width: 100%;
	height: 58px;
	background: #2E3C4D;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	color: #FFFFFF;

	p{
		padding: 0 12px;
	}
}
</style>
