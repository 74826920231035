<template>
	<div class="text" flex="cross:center">
		<img src="@/assets/imgs/icon/crumbs-locaition.png" />当前位置：<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		
	},
	data() {
		return {
		};
	},
	mounted() {
		
	},
	methods: {
		
	},
};
</script>

<style scoped lang="scss">
.text{
	img{
		margin-right: 13px;
	}

	height: 13px;
	font-size: 12px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #4F4F4F;
	line-height: 13px;
}
</style>

